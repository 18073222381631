// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-card-js": () => import("./../../../src/pages/BlogCard.js" /* webpackChunkName: "component---src-pages-blog-card-js" */),
  "component---src-pages-blog-common-footer-js": () => import("./../../../src/pages/BlogCommonFooter.js" /* webpackChunkName: "component---src-pages-blog-common-footer-js" */),
  "component---src-pages-blog-row-js": () => import("./../../../src/pages/BlogRow.js" /* webpackChunkName: "component---src-pages-blog-row-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/Footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/Header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-blog-card-trends-js": () => import("./../../../src/pages/MainBlogCardTrends.js" /* webpackChunkName: "component---src-pages-main-blog-card-trends-js" */),
  "component---src-pages-perspective-js": () => import("./../../../src/pages/Perspective.js" /* webpackChunkName: "component---src-pages-perspective-js" */),
  "component---src-pages-perspective-navigation-js": () => import("./../../../src/pages/PerspectiveNavigation.js" /* webpackChunkName: "component---src-pages-perspective-navigation-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/Share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-sidebar-js": () => import("./../../../src/pages/Sidebar.js" /* webpackChunkName: "component---src-pages-sidebar-js" */),
  "component---src-pages-trends-js": () => import("./../../../src/pages/Trends.js" /* webpackChunkName: "component---src-pages-trends-js" */),
  "component---src-pages-trends-navigation-js": () => import("./../../../src/pages/TrendsNavigation.js" /* webpackChunkName: "component---src-pages-trends-navigation-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */)
}

